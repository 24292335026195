import React from 'react';
import {Container, Col, Row, Card} from 'react-bootstrap';
import './AboutBody.css';


function AboutBody(){
    return(
        <Row>
            <Col></Col>
            <Col lg={8}>
                <Row>
                    <h1>About Jakob Simonsen</h1>
                        <p>Hi 👋.
                        <br/>My name is Jakob Simonsen and I am currently a master's student at the
                        <br/> Norwegian University of science and technology(NTNU) studying
                        <br/>Computer Science with my main subject in algorithms. In the fall of 2021
                        <br/>and spring of 2022, I will be writing my master's thesis in Quantum
                        <br/>computing in collaboration with IBM and Sintef about approximation
                        <br/>algorithms on quantum computers.</p>
                        <p>A short introduction about my past, I grew up in Bodø, Norway, after high
                        <br/>school I moved to Oslo, Norway to study Software engineering at Oslo
                        <br/>Metropolitan University where I earned my degree in 2020. Throughout
                        <br/>my academic career, I have also been working for IBM as a software
                        <br/>engineer. We have done projects on everything from data labeling and
                        <br/>gathering to supply chain management with blockchain and sensor
                        <br/>technology.
                        </p>
                        <br/>
                        <p>Jakob.simonsen@outlook.com</p>

                </Row>
            </Col>
            <Col></Col>
        </Row>
    )
}


export default AboutBody;

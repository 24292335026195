import React from 'react';
import {Container, Col, Row, Card} from 'react-bootstrap';
import './ArticleBody.css';


function ArticleBody(){
    return(
        <Row>
            <Col/>
            <Col lg={8}>
                <h1>Articles are comming soon </h1>
                <p>The first article will be on how to get started with quantum computing with Qiskit from a computer scientist</p>
            </Col>
            <Col/>
        </Row>
    )
}

export default ArticleBody;

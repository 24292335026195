import React from 'react';
import {NavbarComp} from '../../commons';

import './Header.css';

function Header(){
    return(
    <section className="header">
        <section className="header-top">
            <section className="header-top_logo">
            </section>
        </section>
    </section>

    )
}

export default Header;

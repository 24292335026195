import React from 'react';
import construction_icon from './assets/icon.png'
import './App.css';
import { Header, FooterComp, NavbarComp} from './components/commons';
import {ContentBody, AboutBody, ProjectBody, ArticleBody} from './components/content/index.js';
import {Col, Row, Container} from 'react-bootstrap';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <Router>
          <div className="App">
          <div className="layer1">
                  <Container>
                      <NavbarComp/>
                              <FooterComp>
                                <span>Powered by Netlify, 2021</span>
                              </FooterComp>
                  </Container>
                  <Switch>
                        <Route exact path="/">
                          <ContentBody/>
                        </Route>
                        <Route exact path="/articles">
                          <ArticleBody/>
                        </Route>
                        <Route exact path="/projects">
                          <ProjectBody/>
                        </Route>
                        <Route exact path="/about">
                          <AboutBody/>
                        </Route>
                  </Switch>
            </div>
          </div>
      </Router>
  );
}

export default App;

import React from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';


function NavbarComp(){
    return(
    <Navbar className="color-nav" variant="dark" fixed="top">
            <Container>
            <Navbar.Brand href="/">Jakob Simonsen</Navbar.Brand>
            <Nav className="justify-content-end">
              <Nav.Link href="/articles">Articles</Nav.Link>
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav>
            </Container>
    </Navbar>
    )
}

export default NavbarComp;

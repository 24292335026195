import React from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import './ProjectBody.css';


function ProjectBody(){
    return(
        <Row>
        <Col/>
        <Col lg={8}>
            <Row>
                <h1>Projects are comming!</h1>
            </Row>
        </Col>
        <Col/>

        </Row>
    )
}

export default ProjectBody;

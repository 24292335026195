import React from 'react';
import {Container, Col, Row, Card} from 'react-bootstrap';
import './ContentBody.css';


function ContentBody(){
    return(
            <Row>
                <Col></Col>
                <Col lg={8} xs={10}>
                    <Row>
                        <div class="icons">
                        <Col>
                            <a href="https://www.instagram.com/jakob.simonsen/" target="_blank">
                            <img src="https://img.icons8.com/ios/250/FFFFFF/instagram-new.png" alt="Instagram"/>
                            </a>
                            <a href="https://www.linkedin.com/in/jakobbsimonsen/" target="_blank">
                            <img src="https://img.icons8.com/ios/250/FFFFFF/linkedin.png" alt="LinkedIn"/>
                            </a>
                            <a href="Jakob_Simonsen_software_Engineer.pdf" target="_blankn" download>
                            <img src="https://img.icons8.com/ios/250/FFFFFF/resume.png" alt="Resume"/>
                            </a>
                        </Col>
                        </div>
                    </Row>
                </Col>
                <Col></Col>
            </Row>
    )
}


export default ContentBody;
